define('ember-state-services/state-for', ['exports', 'ember', 'ember-weakmap/weak-map'], function (exports, _ember, _emberWeakmapWeakMap) {
  exports['default'] = stateFor;
  var computed = _ember['default'].computed;
  var assert = _ember['default'].assert;
  var getOwner = _ember['default'].getOwner;

  /*
   * Each key in this POJO is a name of a state with the value
   * for that key being a WeakMap.
   */
  var weakMaps = {};

  /*
  * Returns a computed property if called like: stateFor('state-name', 'property-name') or
  * returns the WeakMap if called like stateFor('state-name'). The most common case will be
  * the former but the latter allows advanced options like:
  *
  * stateFor('state-name').delete(this.get('property-name'));
  * stateFor('state-name').has(this.get('some-other-prop'));
  * stateFor('state-name').set(this.get('property-name'), {}); // Overrides the state
  * stateFor('state-name').get(this.get('property-name'));
  */

  function stateFor(stateName, propertyName) {
    if (arguments.length === 1) {
      return weakMaps[stateName];
    }

    assert('The second argument must be a string', typeof propertyName === 'string');

    return computed(propertyName, function () {
      var propertyValue = this.get(propertyName);

      // if the propertyValue is null/undefined we simply return null/undefined
      if (!propertyValue || typeof propertyValue === 'undefined') {
        return propertyValue;
      }

      if (typeof propertyValue !== 'object' && typeof propertyValue !== 'function') {
        throw new TypeError('The state key must resolve to a non primitive value');
      }

      if (!weakMaps[stateName]) {
        weakMaps[stateName] = new _emberWeakmapWeakMap['default']();
      }

      var state = weakMaps[stateName];

      if (!state.has(propertyValue)) {
        var newState = createStateFor(this, stateName, getOwner(this));
        state.set(propertyValue, newState);
      }

      return state.get(propertyValue);
    });
  }

  /*
   * Looks up the state factory on the owner and sets initial state
   * on the instance if desired.
   */
  function createStateFor(context, stateName, owner) {
    var defaultState = {};
    var StateFactory = owner.factoryFor('state:' + stateName);

    if (!StateFactory) {
      return _ember['default'].Object.create();
    }

    var StateFactoryClass = StateFactory['class'];

    if (typeof StateFactoryClass.initialState === 'function') {
      defaultState = StateFactoryClass.initialState.call(StateFactoryClass, context);
    } else if (StateFactoryClass.initialState) {
      throw new TypeError('initialState property must be a function');
    }

    if (_ember['default'].Object.detect(StateFactoryClass)) {
      return StateFactoryClass.create(defaultState);
    }

    return _ember['default'].Object.create(StateFactoryClass);
  }
});